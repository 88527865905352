import classNames from "classnames";
import React from "react";
import LoadingSpinner from "../../../../components/loading-spinner";
import UpdateScenarioFinance from "./update-scenario-finance";

export const UPDATE_STEPS = {
  INITIAL: "initial",
  PREVIEW: "preview",
  SAVE: "save",
  COMPLETED: "completed",
};

const ScenarioFinanceInputGroup = ({
  isLoading,
  isError,
  currentUpdateStep,
  previewParams,
  onPreviewParamsChange,
  onPreview,
  onSave,
  secondFragment,
}) => {
  const isAtPreviewStep = currentUpdateStep === UPDATE_STEPS.PREVIEW;
  const isAtSaveStep = currentUpdateStep === UPDATE_STEPS.SAVE;
  const isAtCompletedStep = currentUpdateStep === UPDATE_STEPS.COMPLETED;

  const handleChangeValue = (value) => {
    onPreviewParamsChange(value);
  };
  const onButtonClick = () => {
    if (isAtSaveStep) {
      onSave();
    } else {
      onPreview();
    }
  };

  const renderButtonContent = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (isAtSaveStep || isAtCompletedStep) {
      return "Save Scenario";
    }
    return "Preview Scenario";
  };

  const renderStatus = () => {
    if (isError) {
      switch (currentUpdateStep) {
        case UPDATE_STEPS.PREVIEW:
          return "Failed to get scenario data";
        case UPDATE_STEPS.SAVE:
          return "Failed to save scenario data";
        default:
          return "";
      }
    }
    if (isAtCompletedStep) {
      return "Your scenario has been updated!";
    }
    return "";
  };

  return (
    <>
      <div className="slider-fragment-container">
        <UpdateScenarioFinance
          handleChangeValue={handleChangeValue}
          financeValue={previewParams}
        />
        {secondFragment}
        <div
          className={classNames("update-scenario-component", {
            expanded: isAtPreviewStep || isAtSaveStep || isAtCompletedStep,
          })}
        >
          {(isAtPreviewStep || isAtSaveStep) && (
            <button
              onClick={onButtonClick}
              type="button"
              onKeyDown={() => {}}
              tabIndex={0}
              className="btn custom-button-dark"
              disabled={isLoading || isAtCompletedStep}
            >
              {renderButtonContent()}
            </button>
          )}

          <span
            className={classNames("update-scenario-status text-color", {
              "text-danger": isError,
            })}
          >
            {renderStatus()}
          </span>
        </div>
      </div>
    </>
  );
};

ScenarioFinanceInputGroup.propTypes = {};

export default ScenarioFinanceInputGroup;
